//import Checkbox from './Checkbox';
//import VStack from './VStack';
//import HStack from './HStack';
//import Form from 'react-bootstrap/Form';

import {
    //useField,
    ErrorMessage
} from 'formik';

export const CustomRadioGroup = ({ field, form, options, ...props }) => {
    //const [field] = useField(props);

    //return <>Hello</>;
    //console.log(field);
    //console.log(props);
    //console.log('---');
    const x = (
        <div className='radio-group'>
            {
                options.map(opt => (
                    <label key={opt.value}
                            style={{paddingRight: '30px'}}
                            >
                        <input
                            {...field}
                            {...props}
                            type="radio"
                            value={opt.value}
                            checked={opt.value === field.value}
                            style={{marginRight: '10px'}}
                        />
                        {opt.desc}
                    </label>
                ))
            }
            <ErrorMessage name={field.name} component="span" className="error" />
        </div>
    );
    //console.log(x);
    return x;
}

export default function YesNoNAField({ ...props }) {
    return (
        <CustomRadioGroup options={[{'value': 'Yes', 'desc': 'Yes'}, {'value': 'No', 'desc': 'No'}, {'value': 'N/A', 'desc': 'N/A'}]} {...props} />
    );
}

export function YesNoField({ ...props }) {
    return (
        <CustomRadioGroup options={[{'value': 'Yes', 'desc': 'Yes'}, {'value': 'No', 'desc': 'No'}]} {...props} />
    );
}

export function OKNAField({ ...props }) {
    return (
        <CustomRadioGroup options={[{'value': 'OK', 'desc': 'OK'}, {'value': 'N/A', 'desc': 'N/A'}]} {...props} />
    );
}

export function YesNoNotSureNAField({ ...props }) {
    return (
        <CustomRadioGroup options={[{'value': 'Yes', 'desc': 'Yes'}, {'value': 'No', 'desc': 'No'}, {'value': 'Not sure', 'desc': 'Not sure'}, {'value': 'N/A', 'desc': 'N/A'}]} {...props} />
    );
}
