import {FormFeedback, FormGroup, Label} from "reactstrap";

export default function ForwardingEmailAddressWhen({ field, form, children, ...props }) {
  return (
    <FormGroup>
        <Label for={field.id} className={"label-color"}>{field.label}</Label>
        <select {...props} {...field}
        style={{
          width: '100%',
        }}
        >
        {/* invalid={Boolean(form.touched[field.name] && form.errors[field.name])} */}
          <option value="">(Please choose an option:)</option>
          <option value="immediately">Immediately upon submitting this form</option>
          <option value="when_left">After the leaving date</option>
        </select>
        {form.touched[field.name] && form.errors[field.name] ? <FormFeedback>{form.errors[field.name]}</FormFeedback> : ''}
    </FormGroup>
  );
}
