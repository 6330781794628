export default function str_to_date(str) {
    if (str === null) {
        return null;
    }
    if (str instanceof Date) {
        console.log("Warning! Already been given a date, but expected a string.");
        return str;
    }
    const [year, month, day] = str.split('-');
    const date = new Date(+year, month - 1, +day);
    /*
    console.log(str);
    console.log(year, month, day);
    console.log(date);
    */
    return date;
}
