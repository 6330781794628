import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

export default function SingleLineField({ field, form, children, ...props }) {
  return (
    <FormGroup>
        <Label for={field.id} className={"label-color"}>{field.label}</Label>
        <Input {...props} {...field} invalid={Boolean(form.touched[field.name] && form.errors[field.name])}
        style={{
          width: '100%',
        }}
        />
        {form.touched[field.name] && form.errors[field.name] ? <FormFeedback>{form.errors[field.name]}</FormFeedback> : ''}
    </FormGroup>
  );
}
