import {
  Link,
} from 'react-router-dom';

import "./ChooseMode.css"
import Roles from "./Roles.js"
import PeopleYouLineManage from "./PeopleYouLineManage.js"
import TableOfLeavers from "./TableOfLeavers.js"
import { useAuth } from "./auth.tsx";
import Heading from "./my_theme/Heading";
import str_to_date from './str_to_date';

import {
  useQuery,
} from "@tanstack/react-query";

function dateDiffInDays(d1, d2) {
  var t1 = d1.getTime();
  var t2 = d2.getTime();
  // t2-t1 is in milliseconds
  return Math.floor((t2-t1)/(24*3600*1000));
}

export default function ChooseMode() {
  let auth = useAuth();
  const roles_for = 'self';
  const { data, status } = useQuery(["roles", roles_for], () =>
    auth.fetch_with_token.get("main/get_roles/" + roles_for)
  );

  const oversight_mode_box = auth.isOverseer ?
    <div>
      <Heading as="h3">Oversight mode</Heading>
      <TableOfLeavers view_name="upcoming/recent" />
      <p>You might also like to review:</p>
      <ul>
        <li>The <Link to="/oversight/test_floor_technician_select">list of current floor technicians</Link></li>
        <li>The <Link to="/oversight/audit_log">audit log</Link></li>
        <li>Who left/should have left/filled in a leavers form <Link to="/oversight/leavers_by_date">between certain dates</Link></li>
        <li>The <Link to="/oversight/month_by_month_summary">month by month log</Link></li>
      </ul>
    </div>
    : <></>;

  let roles = <></>;
  let leaving_link = <p>Once the system has checked your eligibility to fill in a leaving form, the link should appear here.</p>;
  if (status === "loading") {
    roles = <p>Details of roles for {roles_for} will appear here...</p>;
  }
  else if (status === "error") {
    roles = <p>Error loading roles for {roles_for}</p>;
  }
  else {
    roles = <Roles roles_for={roles_for} data={data} />;
    // look through all the roles to check that there is one that is current, finished within the last 31 days, or starts within the next 31 days
    let qualifies = false;
    for (var role_index in data.data) {
      const role = data.data[role_index];
      if (role.status === 'Current') {
        qualifies = true;
        break;
      }
      const end_date_to_consider = role.end_date ?? role.intended_end_date;
      if ((end_date_to_consider !== null) && (end_date_to_consider !== undefined) && Math.abs(dateDiffInDays(str_to_date(end_date_to_consider), new Date())) <= 31) {
        qualifies = true;
        break;
      }
    }
    if (qualifies) {
      leaving_link = <Link to={"/main/self"}>I'm leaving or have left all {/*one or more */}of my above roles</Link>;
    } else {
      leaving_link = <p>You cannot fill in a leaving form because you are apparently not a current or recent member of the Department.</p>;
    }
  }

  return (
    <div className="ChooseMode">
      <main>
        <div className="leaving_self">
          <Heading as="h2">Leaving yourself?</Heading>
          <Heading as="h3">Your roles/visitorships etc:</Heading>
          <div>
            {roles}
          </div>
          {leaving_link}
        </div>
        <div className="manage_other_leavers">
          <Heading as="h2">Manage other leavers</Heading>
          <div>
            <PeopleYouLineManage roles_for="self" />
          </div>
          {oversight_mode_box}
        </div>
      </main>
    </div>
  );
}
