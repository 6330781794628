export default function ProjectLight({children}) {
    return <>
<a href="#nav" className="campl-skipTo" tabIndex="1">skip to navigation</a>
<a href="#content" className="campl-skipTo" tabIndex="2">skip to content</a>

<div className="campl-row campl-global-header">
  <div className="campl-wrap clearfix">
    <div className="campl-header-container campl-column12" id="global-header-controls">
      <a href="http://www.cam.ac.uk/" className="campl-main-logo">
        <img alt="" src="/static/pl/images/interface/main-logo-small.png" />
      </a>
    </div>
    <div className="campl-column2">
    </div>
  </div>
</div>

<div className="campl-row campl-page-header global-page">
  <div className="campl-wrap clearfix">
    <div className="campl-column12">
      <div className="campl-content-container">
        <p className="campl-page-title"><a href="https://www.ch.cam.ac.uk/" style={{ 'color': 'inherit', 'textDecoration': 'inherit' }}>Yusuf Hamied Department of Chemistry</a></p>
      </div>
    </div>
  </div>
</div>
<div className="campl-row campl-content" id="content">
  <div className="campl-wrap clearfix">
    <div className="campl-column12 campl-main-content">
      <div className="campl-column10">
        <div className="campl-content-container">
          {children}
        </div>
      </div>
    </div>
  </div>
</div>
<div className="campl-row campl-local-footer campl-app-footer">
  <div className="campl-wrap clearfix">
    <div className="campl-column6 campl-footer-navigation">
      <p className="campl-app-footer-content">This website is provided by the <a href="http://www.ch.cam.ac.uk/computing">Yusuf Hamied Department of Chemistry Computer Officers</a>.</p>
    </div>
    <div className="campl-column3 campl-footer-navigation">
      <div className="campl-content-container campl-navigation-list">
        <h3><a href="http://www.ch.cam.ac.uk/computing/contact-computer-officers">Contact us</a></h3>
      </div>
    </div>
    <div className="campl-column3 campl-footer-navigation last">
      <div className="campl-content-container campl-navigation-list">
        <h3><a href="http://www.ch.cam.ac.uk">Yusuf Hamied Department of Chemistry</a></h3>
      </div>
    </div>
  </div>
</div>

<div className="campl-row campl-global-footer">
  <div className="campl-wrap clearfix">
    <div className="campl-column3 campl-footer-navigation">
      <div className="campl-content-container campl-navigation-list">
        <p>&#169; 2022 University of Cambridge</p>
      </div>
    </div>
    <div className="campl-column3 campl-footer-navigation">
      <div className="campl-content-container campl-navigation-list">
        <div className="link-list">
          <h3><a href="http://www.cam.ac.uk/study-at-cambridge">Study at Cambridge</a></h3>
        </div>
      </div>
    </div>
    <div className="campl-column3 campl-footer-navigation">
      <div className="campl-content-container campl-navigation-list">
        <h3><a href="http://www.cam.ac.uk/about-the-university">About the University</a></h3>
      </div>
    </div>
    <div className="campl-column3 campl-footer-navigation last">
      <div className="campl-content-container campl-navigation-list">
        <h3><a href="http://www.cam.ac.uk/research">Research at Cambridge</a></h3>
      </div>
    </div>
  </div>
</div>
  </>;
/*
        <!--script type="text/javascript" src="/static/pl/js/libs/ios-orientationchange-fix.js"></script>
        <script type="text/javascript" src="/static/pl/js/libs/jquery-min.js"></script>
        <script type="text/javascript" src="/static/pl/js/libs/modernizr.js"></script>
        <script type="text/javascript" src="/static/pl/js/custom.js"></script-->
*/
}
