import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
//  Routes,
//  Route,
  Link,
  useNavigate,
  useLocation,
  useParams,
//  Navigate,
  Outlet,
  //redirect,
  useRouteError,
} from "react-router-dom";
import ChooseMode from './ChooseMode';
//import myfetch from "./myfetch";
import LeavingForm from "./LeavingForm";
import SubmittedThankYou from "./SubmittedThankYou";

import oversight_routes from "./oversight_routes.tsx";
import ProtectedPage from "./ProtectedPage.tsx";

import Heading from './my_theme/Heading';
//import Input from './my_theme/Input';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { RequireAuth, AuthStatus, AuthProvider, useAuth, Masquerade } from './auth.tsx';

import './App.css';

const raven_auth_url = 'https://accounts.google.com/o/oauth2/auth?client_id=370078087477-cinfivjlip24c3jg878l2r8vhn722si8.apps.googleusercontent.com&response_type=code&redirect_uri=' + process.env.REACT_APP_API_BASE_URL + 'auth/raven_return/&hd=cam.ac.uk&scope=email';

const pronouns = {
  'self': {
    'subject': 'you',
    'possessive': 'your',
    'subject_initial_capital': 'You',
    'possessive_initial_capital': 'Your',
    'to_be': 'are',
    'to_have': 'have',
  },
  'other': {
    'subject': 'they',
    'possessive': 'their',
    'subject_initial_capital': 'They',
    'possessive_initial_capital': 'Their',
    'to_be': 'are',
    'to_have': 'have',
  }
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <RootPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/login/:method",
        element: <LoginPage />,
        /*
        loader: async ({params}) => {
          if (params.method == 'raven') {
            // DOESN'T WORK: react will make this relative even if the target is absolute!
            // so it redirects to e.g. http://localhost:8000/login/raven/https://accounts.google.com/o/oauth2/auth?...
            return redirect(raven_auth_url);
          }
        },
        */
      },
      {
        path: "/logout",
        element: <LogoutPage />,
      },
      {
        path: "/main/*",
        element: (
              <RequireAuth>
                <ProtectedPage />
                <Outlet />
              </RequireAuth>
            ),
        children: [
          {
            path: "self",
            element: <LeavingForm targetCrsid={"self"} editable={true} pronouns={pronouns.self} />,
          },
          {
            path: "others",
            element: <div>others</div>,
          },
          {
            path: "masquerade",
            element: <Masquerade />,
          },
          {
            path: "form/:crsid",
            element: <LeavingFormFromRoute />,
          },
          {
            path: "start_form/:crsid",
            element: <LeavingFormFromRoute allow_start={true} />,
          },
          {
            path: "form_submitted/:crsid/:edition",
            element: <SubmittedThankYou />,
          },
          {
            path: "",
            element: <ChooseMode />,
          },
          {
            path: "*",
            element: <>404</>,
            loader: async ({request, params}) => {
              throw new Response("No such page: /main/" + params['*'], { status: 404 });
            },
          },
        ],
      },
      oversight_routes,
    ],
    errorElement: <RouteErrorBoundary />,
  },
]);

export default function App() {
  return (
    <AuthProvider>
      {/*<Heading as="h2">Requires login</Heading>*/}

      <RouterProvider router={router} />
    </AuthProvider>
  );
}

function LeavingFormFromRoute({allow_start = false}) {
  const params = useParams();
  const auth = useAuth();
  const crsid = params.crsid;
  if (!crsid) {
    return <div>CRSID missing</div>;
  }
  const pronouns_to_use = (crsid === auth.crsid) ? pronouns.self : pronouns.other;
  const editable = false; // TODO: FIXME:
  return (
    <LeavingForm targetCrsid={crsid} editable={editable} pronouns={pronouns_to_use} allow_start={allow_start} />
  );
  /*<div>form for {crsid}</div>*/
}

function Layout() {
  return (
    <div>
      <AuthStatus />
      {/*<CurrentPageStatus />*/}

      {/*<ul>
        <li>
          <Link to="/">Public Page</Link>
        </li>
        <li>
          <Link to="/main">Protected Page</Link>
        </li>
      </ul>*/}

      <Outlet />
    </div>
  );
}

function RouteErrorBoundary() {
  let error = useRouteError();
  let location = useLocation();
  console.error(error);
  let error_details = "(Unable to show details.)";
  if (error instanceof Error) {
    error_details = error.toString();  // JSON.stringify(error) will return '{}'!
  } else {
    try {
      error_details = JSON.stringify(error);
    } catch (error2) {
      console.log("Cannot serialise error:", error2);
      error_details = "(Unable to show details. See console.)";
    }
  }
  return (<div>
    <h2>404</h2>
    <p>
      That page (<code>{location.pathname}</code>) doesn't exist or is not working correctly.
      You might want to <Link to="/">go back to the home page</Link>.
    </p>
    <p>
      Technical details: {error_details}
    </p>
  </div>);
}

function CurrentPageStatus() {
  let location = useLocation();

  return <p>You are visiting page {location.pathname} with {JSON.stringify(location)}</p>;
}

function RootPage() {
  let auth = useAuth();
  if (!auth.crsid) {
    return (<PublicPage />);
  } else {
    return (<ChooseMode />);
  }
}

function LogoutPage() {
  let auth = useAuth();
  //let navigate = useNavigate();
  //auth.signout(() => navigate("/"));
  React.useEffect(() =>
    auth.signout(() => null)
  );
  return (<p>You are now signed out. You may <Link to="/">return to the home page</Link> or close your browser.</p>)
}

function LoginPage() {
  let location = useLocation();
  let auth = useAuth();
  let params = useParams();

  //return <>{JSON.stringify(params)}</>;

  let from = location.state?.from?.pathname || "/";

  if (from.startsWith('/login/') || from === '/login') {
    from = '/';
  }

  if (auth.crsid) {
    // check that the token is still valid by asking the server
    // if this returns with a failure, it should automatically sign out the user
    auth.fetch_with_token.get(auth.authCheckUrl);
    return (<p>You are already logged in. <Link to="/">Return to the target page {from}</Link> or <Link to="/logout">logout</Link>.</p>);
  }

  const method_map = {
    '': LoginMethodChoose,
    'raven': LoginPageRaven,
    'admitto': LoginPageAdmitto,
    'email': LoginPageEmail,
  };

  let method = (params.method ?? '');
  if (Object.keys(method_map).includes(method)) {
      const Component = method_map[method];
      return <>
        <Heading as="h2">Login</Heading>
        <Component from={from} />
      </>;
      // it is not necessarily true that the user accessed the login page from a protected page
      // they could also click the login button from any public page
      //{/*<p>You must log in to view the page at {from}</p>*/}
  } else {
     throw new Response("No such login method " + method, { status: 404 });
  }
}

function LoginMethodChoose({from = '/'}) {
  return (
    <div>
      <p>You can login using any of the following:</p>
      <ul>
        <li>{/*<Link to='/login/raven' state={{ from: from }}>Raven</Link>*/}<a href={ raven_auth_url }>Raven</a> (UIS account)</li>{/* There's seemingly no way to remember the 'from' state with modern Raven, unless saving it in the session */}
        <li><Link to='/login/admitto' state={{ from: from }}>Admitto</Link> (Chemistry account)</li>
        <li><Link to='/login/email' state={{ from: from }}>E-mail (send a time-limited one-time link)</Link> (no account required)</li>
      </ul>
      <p>If you have a Raven or Admitto account, that account is still working and you know the corresponding password, then you may use the respective option.</p>
      <p>If you are a member of Wren and don't have a Raven or Raven for life account then please use the one-time email link login option.</p>
    </div>
  );
}

function LoginPageAdmitto({from = '/'}) {
  // uses uncontrolled Input components
  let [displayStatus, setDisplayStatus] = React.useState<any>(null);
  let auth = useAuth();
  let navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    setDisplayStatus('Logging in...');
    /*let signinret = */auth.signin({'username': username, 'password': password}, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      setDisplayStatus('Success.');
      navigate(from, { replace: true });
    }).catch(error => setDisplayStatus('Failed: ' + error)).then(response => {
      if (response !== true) {
        setDisplayStatus('Failed: ' + response.message);
      }
    });
    // signinret is a promise
  }

  return (
    <>
      <Heading as="h3">Admitto login:</Heading>
      <form onSubmit={handleSubmit}>
      {/*
        <FormControl isRequired>
          <FormLabel>User name (CRSID)</FormLabel>
          <Input name="username" type="text" placeholder="spqr1" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Password</FormLabel>
          <Input name="password" type="password" />
        </FormControl>
      */}
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Admitto username (CRSID):</Form.Label>
          <Form.Control type="text" placeholder="Enter CRSID" name="username" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password:</Form.Label>
          <Form.Control type="password" placeholder="Password" name="password" />
        </Form.Group>
        {" "}
        <Button variant="primary" type="submit">Login</Button>
        <p>{ displayStatus }</p>
      </form>
      <p>
        Or, <Link to="/login">choose a different way to login</Link>.
      </p>
    </>
  );
}

function LoginPageRaven() {
  //const navigate = useNavigate();
  //const location = useLocation();
  //return <div>Logging in with Raven is not currently working. <Link to="/login">Try a different method</Link></div>;
  return <>Please visit <a href={raven_auth_url}>{raven_auth_url}</a> to login with Raven.</>;
  //navigate(oauth2_auth_url, {'replace': true, 'state': location.state}); // You should call navigate() in a React.useEffect(), not when your component is first rendered.
  //import { Navigate } from "react-router-dom";
  //return <Navigate to={oauth2_auth_url} replace={true} />
}

function LoginPageEmail() {
  //return <div>Logging in with a temporary one-time password by email is not currently working. <Link to="/login">Try a different method</Link></div>;
  // uses uncontrolled Input components
  let [displayStatus, setDisplayStatus] = React.useState<any>(null);
  let auth = useAuth();
  let navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;

    setDisplayStatus('Sending e-mail...');
    /*let signinret = */auth.email_link({'email': email}, () => {
      setDisplayStatus('E-mail has been sent. If your e-mail address is registered with the Department, then it will contain a link to login. Please click on the link within 10 minutes or request another one.');
    }).catch(error => setDisplayStatus('Failed: ' + error)).then(response => {
      if (response !== true) {
        setDisplayStatus('Failed: ' + response.message);
      }
    });
    // signinret is a promise
  }
  return (
    <>
      <Heading as="h3">Login via temporary one-time password sent by e-mail:</Heading>
      <p>Please use the e-mail address that is registered with the Department.</p>
      <p>You will be sent an e-mail with a login link that will be valid for 10 minutes. Once logged in, your session will last for the usual duration.</p>
      <form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Email address:</Form.Label>
          <Form.Control type="text" placeholder="Enter Email address" name="email" />
        </Form.Group>
        {" "}
        <Button variant="primary" type="submit">E-mail login link</Button>
        <p>{ displayStatus }</p>
      </form>
      <p>
        Or, <Link to="/login">choose a different way to login</Link>.
      </p>
    </>
  );
}

function PublicPage() {
  return <div>
    <Heading>Welcome</Heading>
    <p>In order to use the online leaving system, you will first need to login using one of the methods below:</p>
    <LoginMethodChoose />
    <p>Please be advised that this site uses local browser storage for login session management and also uses essential cookies when using the Raven login method.</p>
  </div>;
}
