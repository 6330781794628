import ResetFormButton from './ResetFormButton';

//import str_to_date from './str_to_date';

import Heading from './my_theme/Heading';

import { useAuth } from "./auth.tsx";

import React from "react";

/*
import {
  useQuery,
} from "@tanstack/react-query";
*/

import Questions, { useQuestions } from "./Questions.tsx";

import {
  Link,
} from "react-router-dom";

export default function LeavingForm(props) {
    let auth = useAuth();
    //const [leavingDateStr, setLeavingDateStr] = React.useState(null);
    const target_crsid_not_self = props.targetCrsid === 'self' ? auth.crsid : props.targetCrsid;
    const allow_start = props.allow_start ?? (props.targetCrsid === 'self');

    const { data, status, error, isFetching } = useQuestions(target_crsid_not_self, null, allow_start);
    const edition = data?.form_edition?.edition;

    let heading = <Heading as="h2">Leaving form for {data?.target_person?.full_name ?? target_crsid_not_self}</Heading>;

    if (status === "loading" || ((status === "success") && (data !== undefined) && !('questions' in data))) { // if we provided initialData then it's never 'loading'
        return <>
          {heading}
          <p>Loading form...</p>
        </>;
    }
    if ((status === "error") || (data === undefined)) {
      if (error?.response?.data?.detail === 'JWT error - possibly expired') {
        return <>
          {heading}
          <p>Your session has expired. Please <Link to="/login">login</Link> again.</p>
        </>;
      }
      else {
        return <>
          {heading}
          <p>Error loading form: {error.message}</p>
          <p>{error?.response?.data?.detail}</p>
        </>;
      }
    }

    return (
        <>
          {heading}
          Please complete this online leavers process when you are due/intending to leave to help facilitate a smooth transition.
          It would be most helpful if you could start this a week or two before you leave, and please ensure that you submit it on or before your last day where possible.
          <Questions targetCrsid={props.targetCrsid} data={data} pronouns={props.pronouns} saveButtonText="Save but do not submit (you can continue to edit now or later)" submitButtonText="Save and submit now" />

          {/*<ResetFormButton variant="secondary" target_person={data.target_person}>
            Reset
          </ResetFormButton>*/}
        </>
    );
}
