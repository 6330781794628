import {
  useQuery,
} from "@tanstack/react-query";

import { useAuth } from "./auth.tsx";
import { TableFromHeadBody } from "./TableFromObj.js"

export default function PeopleYouLineManage(props) {
  let auth = useAuth();
  const targetCrsidNotSelf = (props.roles_for === 'self' ? auth.crsid : props.roles_for);
  const { data, status } = useQuery(["managees", targetCrsidNotSelf], () =>
    auth.fetch_with_token.get("main/get_managees/" + props.roles_for)
  );

  if (status === "loading") {
    return <p>Details of people line managed by {props.roles_for} will appear here...</p>;
  }
  if (status === "error") {
    return <p>Error loading list of people line managed by {props.roles_for}</p>;
  }

  const keep_keys = {'person_name': 'Name', 'job_title': 'Job title', 'post_category': 'Post category', 'line_manager_name': 'Line managed by', 'estimated_leaving_date': 'Estimated leaving date'};
  const adj_data = data.data.managees.map((role_data) => Object.keys(keep_keys).map(key => role_data[key]));

  const people_table = (!data.data.managees.length) ? 
    <p>You are not recorded as being the line manager of any person in Chemistry.</p>
  : <>
      The people that you line manage or that are in your research group should appear here (if you are a PI or secretary). This list will include people who left up to 31 days ago.
      <TableFromHeadBody theadData={Object.values(keep_keys)} tbodyData={adj_data} formatter={(x)=>x} />
    </>
  ;

  const research_groups = (!data.data.research_groups.length) ?
    <p>You are not recorded as being the administrator of any Chemistry research groups. (If this is incorrect, please contact Kathleen Pickett.)</p>
  : <p>You are recorded as being the administrator of the following Chemistry research group(s): {data.data.research_groups.map((group) => group.research_group_name).join(', ')}. (If this is incorrect or incomplete, please contact Kathleen Pickett.)</p>
  ;

  return (
    <div className="Roles">
      <main>
        { research_groups }
        { people_table }
      </main>
    </div>
  );
}
