import { TableFromHeadBody } from "./TableFromObj.js"

function Roles({data}) {
  const keep_keys = {'job_title': 'Job title', 'post_category': 'Post category', 'line_manager_name': 'Line managed by', 'estimated_leaving_date': 'Estimated leaving date'};
  let adj_data = data.data.map((role_data) => Object.keys(keep_keys).map(key => role_data[key]));

  return (
    <div className="Roles">
      <main>
        <TableFromHeadBody theadData={Object.values(keep_keys)} tbodyData={adj_data} formatter={(x)=>x} />
      </main>
    </div>
  );
}

export default Roles;
