import {
  useQuery,
} from "@tanstack/react-query";

import { useParams } from "react-router-dom";
import { useAuth } from "./auth.tsx";
import TableFromObj from "./TableFromObj.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDatabase,
  faTableList,
  faCheck,
  faXmark,
  faSuitcaseMedical as faSolidSuitcaseMedical,
  faFireExtinguisher as faSolidFireExtinguisher,
} from '@fortawesome/free-solid-svg-icons'

import Button from "./my_theme/Button";

import {
  Link,
} from "react-router-dom";

// these icons are only available in fa Pro, so use the free versions instead
const faRegularSuitcaseMedical = faSolidSuitcaseMedical;
const faRegularFireExtinguisher = faSolidFireExtinguisher;

function cell_style(x, {key, row}, extra) {
  const today = new Date().toISOString().split("T")[0];
  let style = {};
  if ((key !== "person_name") && (key !== "post_category")) {
    style.textAlign = 'center';
  }
  if (row && (key === "person_name" || key === "estimated_leaving_date" || key === "leavers_form_ever_submitted")) {
    if (row.leavers_form_ever_submitted) {
      style.backgroundColor = 'lime';
    } else if (row.estimated_leaving_date < today) {
      style.backgroundColor = 'pink';
    } else if (row.estimated_leaving_date === today) {
      style.backgroundColor = 'yellow';
    }
  }
  return {'style': style};
}

function format_table_data(x, {col_index, key, row}) {
  if (typeof x === "boolean") {
    if (x) {
      return <FontAwesomeIcon icon={faCheck} />;
    } else {
      return <FontAwesomeIcon icon={faXmark} />;
    }
  }
  return x;
}

function tristate_format(x, trueCase, falseCase, nullCase) {
  // undefined is assumed to be the same as null
  if (x === true) {
    return trueCase;
  } else if (x === false) {
    return falseCase;
  } else if ((x === null) || (x === undefined)) {
    return nullCase;
  }
  console.error("Value", x, " is not tristate");
  //throw new Error("Value is not tristate");
  return undefined;
}

export default function TableOfLeavers(props) {
  let auth = useAuth();
  // we could be told the date range to use either through props or (router) params
  // or we could rely on the server-side defaults if we don't explicitly specify a range
  // so agree on which range to ask for first
  let params = useParams();
  let props_or_params = {};
  Object.assign(props_or_params, props, params);
  const query_params = ['date_from', 'date_to'].filter(key => key in props_or_params).reduce((ret, key) => { ret[key] = props_or_params[key]; return ret; }, {}); // pick these two fields from props_or_params, if present
  // now make the request
  const query_key = ["leavers"].concat(Object.keys(query_params).length === 0 ? [] : [query_params['date_from'] ?? null, query_params['date_to'] ?? null]);
  const { data, status } = useQuery(query_key, () =>
    auth.fetch_with_token.get("main/get_leavers", {"params": query_params})
  );

  const view_name_long = (props.view_name === 'upcoming/recent') ? "upcoming/recent leavers" : "leavers within the specified time window (" + query_params.date_from + " to " + query_params.date_to + ")";
  const heading = (props.view_name === 'upcoming/recent') ? "have left or completed their leavers form in the past 31 days, or are due to leave in the next 31 days" : "left, completed their leavers form, or are/were due to the leave within the specified time window (" + query_params.date_from + " to " + query_params.date_to + ")";

  if (status === "loading") {
    return <p>Details of {view_name_long} will appear here...</p>;
  }
  if (status === "error") {
    return <p>Error loading list of {view_name_long}</p>;
  }

  if (!data.data.length) {
    if (props.view_name === 'upcoming/recent') {
      return <p>Nobody has left recently, nor submitted their leavers form recently, and nor is anyone due to leave in the next 31 days.</p>;
    }
    else {
      return <p>Nobody left, is or were due to leave, nor submitted their leavers form within the specified time window.</p>;
    }
  }

  const keep_keys = {
    'person_name': 'Name',
    'post_category': 'Post category',
    'estimated_leaving_date': 'Leaving date',
    'leavers_form_exists': 'Has viewed leavers form?',
    'leavers_form_ever_submitted': 'Has submitted leavers form?',
    'leavers_form_latest_updates_submitted': 'Latest form submitted?',
    'hotwire': 'Hotwire',
    'form': 'View/edit form',
    'firstaider_qualification_up_to_date': 'Is/was first-aider?',
    'fire_warden_in_date': 'Is/was fire warden?'
  };
  const manip_keys = {
    'person_name': (role_data) => <span title={role_data.person_crsid}>{role_data.person_name}</span>,
    'hotwire': (role_data) => <a href={"https://chemdb.ch.cam.ac.uk/hotwire3/chemistry/edit.php?_view=10_View/People/Personnel_History&_id=" + role_data.person_id}><FontAwesomeIcon icon={faDatabase} /></a>,
    'form': (role_data) => (role_data.leavers_form_exists ? <Link to={"/main/form/" + role_data.person_crsid}><FontAwesomeIcon icon={faTableList} /></Link> : "N/A"),
    'firstaider_qualification_up_to_date': (role_data) => tristate_format(role_data.first_aider_qualification_up_to_date, <FontAwesomeIcon icon={faSolidSuitcaseMedical} />, <FontAwesomeIcon icon={faRegularSuitcaseMedical} style={{color: 'red'}} />, <></>),
    'fire_warden_in_date': (role_data) => tristate_format(role_data.fire_warden_in_date, <FontAwesomeIcon icon={faSolidFireExtinguisher} />, <FontAwesomeIcon icon={faRegularFireExtinguisher} style={{color: 'red'}} />, <></>),
  };
  const adj_data = data.data.map((role_data) => Object.fromEntries(Object.keys(keep_keys).map(key => [key, (key in manip_keys) ? manip_keys[key](role_data) : role_data[key]])));

  const exportToSpreadsheet = () => {
    // ask the server to do it
    // and don't necessarily require it to be exactly the same data as shown here,
    // if our view is stale
    let p = {'data_response_type': 'xlsx'};
    Object.assign(p, query_params);
    auth.fetch_with_token.get("main/get_leavers", {"params": p, responseType: 'blob'}).then(response => {
      // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

      const element = document.createElement("a");
      const href = URL.createObjectURL(response.data);
      //element.href = process.env.REACT_APP_API_BASE_URL + "main/get_leavers?" + (new URLSearchParams(p)).toString();
      element.href = href;
      element.download = "leavers_" + (query_params.date_from ?? '') + "_" + (query_params.date_to ?? '') + ".xlsx";
      element.target = "_blank";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <div className="Roles">
      <main>
        <p>The following people {heading}:</p>
        <TableFromObj headings={keep_keys} data={adj_data} formatter={format_table_data} cellStyles={cell_style} />
        <Button onClick={exportToSpreadsheet}>Export to spreadsheet</Button>
      </main>
    </div>
  );
}
