import {FormFeedback, FormGroup, Label} from "reactstrap";

import { useAuth } from "./auth.tsx";

/*
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
*/

import {
  useQuery,
//  useQueryClient,
} from "@tanstack/react-query";

export function useFloorTechnicians() {
    let auth = useAuth();
    //let navigate = useNavigate();
    //let location = useLocation();
    return useQuery({
        queryKey: ["floor_technicians"],
        queryFn: async () => {
            const { data } = await auth.fetch_with_token.get("main/floor_technicians", {"params": {}});
            return data;
        },
        onError: (error) => {
            if (error?.status === 401 && error?.response?.data?.detail === "JWT error - possibly expired") {
                // TODO: auth.redirect_to_login();
            }
        },
    });
}

export default function FloorTechnicianSelect({ field, form, children, ...props }) {
  const { data, status, error, isFetching } = useFloorTechnicians();

  if (status === "loading") {
    return <p>Loading list of floor technicians to choose from...</p>;
  }
  if (status === "error") {
    return <p>Error loading list of floor technicians to choose from.</p>;
  }

  let options = [<option value="" key="blank">(Please choose an option:)</option>, <option value="N/A" key="N/A">N/A - I don't have one</option>, <option value="Don't know" key="Don't know">I don't know</option>];
  for (var i = 0; i < data.length; i++) {
    options.push(<option value={data[i].crsid} key={data[i].crsid}>{data[i].person_name} ({data[i].job_title})</option>);
  }

  return (
    <FormGroup>
        <Label for={field.id} className={"label-color"}>{field.label}</Label>
        <select {...props} {...field}
        style={{
          width: '100%',
        }}
        >
        {/* invalid={Boolean(form.touched[field.name] && form.errors[field.name])} */}
          { options }
        </select>
        {form.touched[field.name] && form.errors[field.name] ? <FormFeedback>{form.errors[field.name]}</FormFeedback> : ''}
    </FormGroup>
  );
}
