import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ProjectLight from './my_theme/ProjectLight';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

import Heading from './my_theme/Heading';

//import axios from "axios";
import {
//  useQuery,
//  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import App from './App.tsx';

//import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <ProjectLight>
    {/*<ChakraProvider>*/}
      <QueryClientProvider client={queryClient}>
        <header>
          <a href="/" style={{ 'color': 'inherit', 'textDecoration': 'inherit' }}><Heading as="h1">
            Online leaving system{/* - Yusuf Hamied Department of Chemistry*/}
          </Heading></a>
        </header>
        <main>
          <App />
        </main>
        <footer>
          <ReactQueryDevtools initialIsOpen />
        </footer>
      </QueryClientProvider>
    {/*</ChakraProvider>*/}
  </ProjectLight>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
