import { RequireAuth, AuthStatus, AuthProvider, useAuth, Masquerade } from './auth.tsx';

import ProtectedPage from "./ProtectedPage.tsx";
import FloorTechnicianSelect from "./FloorTechnicianSelect";
import AuditLog from "./AuditLog";
import LeaversChooseDateRange from "./LeaversChooseDateRange";
import TableOfLeavers from "./TableOfLeavers";
import MonthByMonthSummary from "./MonthByMonthSummary";

import {
  Link,
  Outlet,
} from "react-router-dom";

const oversight_routes = {
  path: "/oversight/*",
  element: (
        <RequireAuth type="oversight">
          <ProtectedPage type="oversight" />
          <p><Link to="/main/">Go back to the main page</Link></p>
          <Outlet />
        </RequireAuth>
      ),
  children: [
    {
      path: "test_floor_technician_select",
      element: <FloorTechnicianSelect field={{id: 'floor_technician_select_test', label: 'Floor technician', name: 'floor_technician'}} form={{touched: {}, errors: {}}} />,
    },
    {
      path: "audit_log",
      element: <AuditLog />,
    },
    {
      path: "leavers_by_date",
      element: <LeaversChooseDateRange />,
    },
    {
      path: "leavers_by_date/:date_from/:date_to",
      loader: ({params}) => { console.log("loaders params", params) },
      element: <><LeaversChooseDateRange /><TableOfLeavers /></>,
    },
    {
      path: "month_by_month_summary",
      element: <MonthByMonthSummary />,
    },
    {
      path: "*",
      element: <>404</>,
      loader: async ({request, params}) => {
        throw new Response("No such page: /oversight/" + params['*'], { status: 404 });
      },
    },
  ],
};

export default oversight_routes;
