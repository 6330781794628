export function TableFromHeadBodyByKey({theadData, tbodyData, formatter=(x)=>x, cellStyles=(x)=>{} }) {
  // here, theadData is a dict and tbodyData is an array of dicts
  const heading_keys = Object.keys(theadData);
  return (
    <table>
      <thead>
        <tr>
          {heading_keys.map((heading, col_index) => {
            return <th key={heading} {...cellStyles(heading, {heading: true, col_index: col_index, key: heading})}>{formatter(theadData[heading], {heading: true, key: heading, col_index: col_index})}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {tbodyData.map((row, row_index) => {
            return <tr key={"row" + row_index}>
              {heading_keys.map((key, col_index) => {
                return <td key={"cell" + row_index + "," + col_index} {...cellStyles(row[key], {heading: false, row_index: row_index, col_index: col_index, key: key, row: row})}>{formatter(row[key], {heading: false, row_index: row_index, col_index: col_index, key: key, row: row})}</td>
              })}
            </tr>;
        })}
      </tbody>
    </table>
  );
}

export function TableFromHeadBody({theadData, tbodyData, formatter=(x)=>x, cellStyles=(x)=>{}}) {
  // here, theadData is an array, and tbodyData is an array of arrays
  return (
    <table>
      <thead>
        <tr>
          {theadData.map((heading, col_index) => {
            return <th key={heading} {...cellStyles(heading, {heading: true, col_index: col_index, key: heading})}>{formatter(heading, {heading: true, key: heading, col_index: col_index})}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {tbodyData.map((row, row_index) => {
            return <tr key={"row" + row_index}>
              {row.map((val, col_index) => {
                return <td key={"cell" + row_index + "," + col_index} {...cellStyles(val, {heading: false, row_index: row_index, col_index: col_index, row: row})}>{formatter(val, {heading: false, row_index: row_index, col_index: col_index, row: row})}</td>
              })}
            </tr>;
        })}
      </tbody>
    </table>
  );
}

export default function TableFromObj({data, headings=null, formatter=(x)=>x, cellStyles=(x)=>{}}) {
  // headings is a dict and data is an array of dicts
  // if no headings are supplied, then they are taken from the FIRST row of the data array
  // (which is assumed to exist!)
  const my_headings = headings ?? Object.fromEntries(Object.keys(data[0]).map(k => [k, k]));
  return (
    <TableFromHeadBodyByKey theadData={my_headings} tbodyData={data} formatter={formatter} cellStyles={cellStyles} />
  );
}
