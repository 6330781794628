import * as React from "react";
import {
  Link,
  //Navigate,
  //useNavigate,
  useLocation,
  //useParams,
} from "react-router-dom";
import {default as myfetch, toFormData} from "./myfetch";

import Button from './my_theme/Button';
import Form from 'react-bootstrap/Form';

const authUrl = 'auth/token';
const authUrl_email = 'auth/email_link';
export const authCheckUrl = 'main/get_user_info';

interface AuthContextType {
  crsid: any;
  token: str;
  signin: (credentials, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  fetch_with_token: any;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [crsid, setCrsid] = React.useState<any>(null);
  let [token, setToken] = React.useState<str>(null);
  let [userFriendlyName, setUserFriendlyName] = React.useState<str>(null);
  let [userFullName, setUserFullName] = React.useState<str>(null);
  let [signingIn, setSigningIn] = React.useState<bool>(false);
  let [isOverseer, setIsOverseer] = React.useState<bool>(false); // don't use this for anything critical - but instead get the backend to verify!
  let [authFlashMessage, setAuthFlashMessage] = React.useState<str>(null);

  function loadCandidateToken(candidate_token, candidate_crsid, origin) {
      setSigningIn(true);
      console.log(`Checking candidate token from ${origin}`);
      // check with the server whether the token is still valid
      // (and for this username)
      // and fetch additional information associated with the user
      myfetch.get(authCheckUrl, {
        headers: {'Authorization': 'Bearer ' + candidate_token},
      }).then(response => {
        if (response.data.crsid === candidate_crsid) {
          console.log(`Using login token from ${origin}`);
          setCrsid(candidate_crsid);
          setToken(candidate_token);
          setUserFriendlyName(response.data.friendly_name);
          setUserFullName(response.data.full_name);
          setIsOverseer(response.data.superuser);
          setAuthFlashMessage(null);
        } else {
          console.log(`Login token from ${origin} has incorrect crsid ${candidate_crsid}, not using`);
          sessionStorage.clear();
          setAuthFlashMessage("Your session appears to have been forged.");
        }
        setSigningIn(false);
      }).catch(function(error) {
        console.log("Token from", origin, "could not be validated by the server.", error);
        sessionStorage.clear();
        setSigningIn(false);
      });
  }

  if ((crsid === null) && !signingIn) {
    // load from cookie if set
    const cookies = document.cookie.split(';').reduce((prev, current) => {
      const [name, value] = current.split(/\s?(.*?)=(.*)/).splice(1, 2);
      prev[name] = value;
      return prev;
    }, {});
    if ('__Host-Raven-JWT' in cookies) {
      //loadCandidateToken(cookies['__Host-Raven-JWT'], cookies['__Host-Raven-Crsid'], 'reasserted Raven cookie');
      sessionStorage.setItem("crsid", cookies['__Host-Raven-Crsid']);
      sessionStorage.setItem("token", cookies['__Host-Raven-JWT']);
    }
  }

  if ((crsid === null) && !signingIn) {
    // load from session storage if still valid
    if (sessionStorage.getItem("token") && sessionStorage.getItem("crsid")) {
      loadCandidateToken(sessionStorage.getItem("token"), sessionStorage.getItem("crsid"), "session storage");
    }
  }

  let signin = (credentials, callback: VoidFunction) => {
    setSigningIn(true);
    setCrsid(null);
    setIsOverseer(false);
    return myfetch.post(authUrl, toFormData(credentials),
      {
        // headers: { "Content-Type": "application/json" },
      },
    ).then(response => {
      // this means we got a successful response
      // (else the server sends a non-200 header resulting in the axios promise failing)
      setCrsid(response.data.user.crsid);
      setToken(response.data.access_token);
      setUserFriendlyName(response.data.user.friendly_name);
      setUserFullName(response.data.user.full_name);
      setIsOverseer(response.data.user.superuser);
      sessionStorage.setItem("crsid", response.data.user.crsid);
      sessionStorage.setItem("token", response.data.access_token);
      setSigningIn(false);
      setAuthFlashMessage(null);
      callback();
      return true;
    }).catch(function(error) {
      return error;
    });
  };

  let email_link = (credentials, callback: VoidFunction) => {
    return myfetch.post(authUrl_email, toFormData(credentials),
      {
        headers: { "Content-Type": "application/json" },
      },
    ).then(response => {
      // this means we got a successful response
      // (else the server sends a non-200 header resulting in the axios promise failing)
      callback();
      return true;
    }).catch(function(error) {
      return error;
    });
  };

  let signout = (callback: VoidFunction) => {
    setCrsid(null);
    setToken(null);
    setUserFriendlyName(null);
    setUserFullName(null);
    setIsOverseer(false);
    sessionStorage.clear();
    setSigningIn(false);
    setAuthFlashMessage(null);
    callback();
  };

  let fetch_with_token = myfetch.create({
    headers: {'Authorization': 'Bearer '+ token}
  });

  /*
  // can't do this here
  let navigate = useNavigate();
  let location = useLocation();
  // since not within a router context...
  */

  fetch_with_token.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    //console.log("fetch", response);
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log("fetch_with_token error:", error);
    if (error?.response?.status === 401 && error?.response?.data?.detail === "JWT expired") {
      console.log("Session expired - get the user to login again");
      signout(() => {
        setAuthFlashMessage("Your session has expired. Please log in again.");
      });
      // by signing the user out, they should automatically be redirected to login again if necessary.
      // hence we don't need the following (which won't work from this context anyway)
      //navigate.navigate("/login", {'state': {'from': location.pathname } });
    }
    return Promise.reject(error);
  });

  //const clearAuthFlashMessage = () => { setAuthFlashMessage(null); };
  // possibly the above is not of much use since it would take effect immediately, whereas we would want it to take effect from the next "page" - whatever that means!

  let value = { crsid, userFriendlyName, userFullName, token, signin, signout, fetch_with_token, loadCandidateToken, isOverseer, authFlashMessage/*, clearAuthFlashMessage*/, email_link };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthStatus() {
  let auth = useAuth();
  //let navigate = useNavigate();
  let location = useLocation();

  if (!auth.crsid) {
    let loginlink = <></>;
    if (!(location.pathname.startsWith('/login/') || location.pathname === '/login')) {
      loginlink = <>{' '}<Link to="/login" state={{ from: location }}>Login now</Link></>;
    }
    return <p>{ auth.authFlashMessage ?? 'You are not logged in.' }{loginlink}</p>;
  }

  return (
    <div>
      <p>
        { auth.authFlashMessage ?? <></> }
        Welcome {auth.crsid}!{" "}
        <Link to="/logout">
          Sign out
        </Link>
      </p>
    </div>
  );
}

export function RequireAuth({ type = 'main', children }: { type : str, children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.crsid) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    //return <Navigate to="/login" state={{ from: location }} />;
    return (
      <div>
        <h2>Login required</h2>
        <p>You must <Link to="/login" state={{ from: location }}>login</Link> to see this page (<code>{ location.pathname }</code>).</p>
      </div>
    )
  }

  if ((type === 'oversight') && (!auth.isOverseer)) {
    return <div>
      <h2>Access denied</h2>
      <p>This page is only accessible to overseers of the system. If you think that you should be able to access this page but cannot, then please contact support@ch.cam.ac.uk providing full details.</p>
    </div>;
  }

  return children;
}

export function Masquerade() {
  let auth = useAuth();
  //let params = useParams();
  //return JSON.stringify(params);
  // TODO: move to https://typeofnan.dev/using-session-storage-in-react-with-hooks/
  if (sessionStorage.getItem("masquerade")) {
    function stopMasquerading() {
      let {token, crsid} = JSON.parse(sessionStorage.getItem("masquerade"));
      sessionStorage.clear();
      auth.loadCandidateToken(token, crsid, 'stop masquerading');
      sessionStorage.setItem("crsid", crsid);
      sessionStorage.setItem("token", token);
    }
    return <>
      <div>
        You are masquerading as {auth.crsid} but you are really {JSON.parse(sessionStorage.getItem("masquerade")).crsid}.
      </div>
      <div><Button onClick={stopMasquerading}>Stop masquerading</Button></div>
      <div><Link to="/">Goto home page</Link></div>
    </>;
  }
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("masquerade_username") as string;

    sessionStorage.setItem("masquerade", JSON.stringify({'crsid': auth.crsid, 'token': auth.token}));
    console.log("Attempting to masquerade...");
    auth.fetch_with_token.post(`/main/masquerade`, {},
      {
        'params': {'target_crsid': username},
      }
    ).then(function(response) {
      console.log("Now masquerading as", response.data.user);
      sessionStorage.setItem("crsid", response.data.user.crsid);
      sessionStorage.setItem("token", response.data.access_token);
      auth.loadCandidateToken(response.data.access_token, response.data.user.crsid, 'masquerade');
    }).catch(function(error) {
      console.log("Failed to masquerade");
    });
  }
  return <>
    <div>
      You are not masquerading as another user.
      <form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Masquerade as (CRSID):</Form.Label>
          <Form.Control type="text" placeholder="Enter CRSID" name="masquerade_username" />
        </Form.Group>
        {" "}
        <Button variant="primary" type="submit">Masquerade</Button>
      </form>
    </div>
    <div><Link to="/">Goto home page</Link></div>
  </>;
}
