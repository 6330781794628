import React from "react";
//import { useField } from "formik";

const MIN_TEXTAREA_HEIGHT = '5em';


export default function TextareaField({ field, form, children, ...props }) {
  return (
    <textarea
      name={field.name}
      {...props}
      value={field.value}
      onBlur={field.onBlur}
      onChange={field.onChange}
      style={{
        minHeight: MIN_TEXTAREA_HEIGHT,
        width: '100%',
      }}
    />
  );
}
