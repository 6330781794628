//import myfetch from "./myfetch";

import {
  useQuery,
} from "@tanstack/react-query";

import React from 'react';

import { useAuth } from "./auth.tsx";
import TableFromObj, { TableFromHeadBody } from "./TableFromObj.js"

/**
 * Retrieve nested item from object/array
 * @param {Object|Array} obj
 * @param {String} path dot separated
 * @param {*} def default value ( if result undefined )
 * @returns {*}
 * https://stackoverflow.com/a/16190716
 */
function path(obj, path, def) {
    for (var i = 0, len = path.length; i < len; ++i) {
        if(!obj || typeof obj !== 'object') return def;
        obj = obj[path[i]];
    }

    if(obj === undefined) return def;
    return obj;
}

export default function AuditLog(props) {
  const [page, setPage] = React.useState(1);
  const [oldPage, setOldPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);
  let auth = useAuth();
  const { data, status, error, isPreviousData, isFetching } = useQuery(["audit_log", pageSize, page], () =>
    auth.fetch_with_token.get("main/audit_log", {"params": {'page': page, 'page_size': pageSize}}), // the backend will check that the user has oversight permissions
    {keepPreviousData: true}
  );

  const mySetPage = function(next_page) {
    setOldPage(page);
    setPage(next_page);
  };

  if (status === "loading") {
    return <p>Fetching audit log...</p>;
  }
  if (status === "error") {
    return <p>Error loading audit log ({error.message})</p>;
  }

  if (!data.data.records.length) {
    return <p>There are currently no entries in the audit log.</p>;
  }

  const keep_keys = {'AuditLog.audit_id': 'id', 'ActorRealPerson.full_name': 'Actor', 'AuditLog.action': 'Action', 'PersonPerson.full_name': 'Person', 'AuditLog.action_notes': 'Notes', 'AuditLog.action_when': 'Date and time'};
  const adj_data = data.data.records.map((audit_data) => Object.keys(keep_keys).map(key => path(audit_data, key.split('.'))));

  const pager = <>
    <span>Current page: {isPreviousData ? oldPage : page} {isPreviousData ? <> (loading page {page})</> : <></>} of {data.data.num_pages}</span>{" "}
      <button
        onClick={() => mySetPage(1)}
        disabled={page === 1}
      >
        &lt;&lt; First
      </button>{' '}
      <button
        onClick={() => mySetPage(old => Math.max(old - 1, 1))}
        disabled={page === 1}
      >
        &lt; Previous
      </button>{' '}
      <button
        onClick={() => {
          //if (!isPreviousData && data.hasMore) {
          if (page < data.data.num_pages) {
            mySetPage(old => old + 1);
          }
        }}
        // Disable the Next Page button until we know a next page is available
        // disabled={isPreviousData || !data?.hasMore}
        disabled={!(page < data.data.num_pages)}
      >
        Next &gt;
      </button>{' '}
      <button
        onClick={() => mySetPage(data.data.num_pages)}
        disabled={page === data.data.num_pages}
      >
        Last &gt;&gt;
      </button>
      {isFetching ? <span> Loading...</span> : null}{' '}
    </>;

  return (
    <div>
      <h3>Audit log</h3>
      {pager}
      <main>
        <TableFromHeadBody theadData={Object.values(keep_keys)} tbodyData={adj_data} formatter={(x)=>x} />
      </main>
      {pager}
    </div>
  );
}
