import React from "react";
import Button from "./my_theme/Button";
import DatePicker from "./my_theme/DatePicker";
import { useNavigate, useParams } from "react-router-dom";

function dateAdd(startDate = null, numDays = 31) {
  if (startDate === null) {
    startDate = new Date();  // default to today's date (yes, this really is needed!)
  }
  var x = new Date(startDate);
  x.setDate(x.getDate() + numDays);
  return x;
}

function dateToStr(date) {
  return date.toISOString().split("T")[0];
}

export default function LeaversChooseDateRange() {
  let params = useParams();
  const defaultStartDate = params.start_date ?? dateAdd(null, -31);
  const defaultEndDate = params.end_date ?? dateAdd(null, 31);
  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  const navigate = useNavigate();

  const showLeavers = () => {
    navigate("/oversight/leavers_by_date/" + dateToStr(startDate) + "/" + dateToStr(endDate));
  }

  return <>
      <h2>Leavers between two dates</h2>
      Date from: <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
      Date to: <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
      />
      <Button onClick={showLeavers}>Show</Button>
    </>
  ;
}
