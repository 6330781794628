import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//console.log(API_BASE_URL);

export default axios.create({
  baseURL: API_BASE_URL,
});

export function toFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
}
