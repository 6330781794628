import {
  useQuery,
} from "@tanstack/react-query";

import React from 'react';

import { useAuth } from "./auth.tsx";

import Button from "./my_theme/Button";

import TableFromObj from "./TableFromObj.js"

export default function MonthByMonthSummary(props) {
  const auth = useAuth();

  const cols = [
     {'key': 'month', 'name': 'Month'},
     {'key': 'started', 'name': 'Started form'},
     {'key': 'submitted', 'name': 'Submitted form'},
     {'key': 'confirmed', 'name': 'Leaving date confirmed'},
     {'key': 'unconfirmed', 'name': 'Leaving date still unconfirmed'},
    ];

  const query_key = ["month_by_month_summary"];
  const { data, status } = useQuery(query_key, () =>
    auth.fetch_with_token.get("main/month_by_month_summary", {'params': {'data_response_type': 'json', 'include_individual_details': false}})
  );

  if (status === "loading") {
    return <p>Summary will appear here...</p>;
  }
  if (status === "error") {
    return <p>Error loading summary</p>;
  }

  if (!Object.keys(data.data).length) {
    return <p>No summary data are available.</p>;
  }

  const exportToSpreadsheet = () => {
    // ask the server to do it
    // and don't necessarily require it to be exactly the same data as shown here,
    // if our view is stale
    const p = {'data_response_type': 'xlsx', 'include_individual_details': true};
    auth.fetch_with_token.get("main/month_by_month_summary", {"params": p, responseType: 'blob'}).then(response => {
      // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

      const element = document.createElement("a");
      const href = URL.createObjectURL(response.data);
      element.href = href;
      element.download = "leavers_summary.xlsx";
      element.target = "_blank";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(href);
    });
  };

  // massage the data format of the data that are coming back...
  const rows = Object.keys(data.data).map((month, month_index) => ({'month': month, ...data.data[month]}));
  const cellStyles = (value, {heading, key}) => (heading ? {style: { width: '200px', textAlign: 'left' }} : (key === 'month' ? {style: { fontWeight: 'bold' } } : null));

  return (
    <div className="Roles">
      <main>
        <p>Here is the month-by-month summary of leavers since this system went live:</p>
        <TableFromObj data={rows} headings={Object.fromEntries(cols.map((col) => [col.key, col.name]))} cellStyles={cellStyles} />
        <Button onClick={exportToSpreadsheet}>Export to spreadsheet with individual leavers' details</Button>
      </main>
    </div>
  );
}
