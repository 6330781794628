import {
  Link,
  useParams,
} from 'react-router-dom';

import {Checkmark} from './react-checkmark/checkmark.js';

export default function SubmittedThankYou() {
  let params = useParams();
  let targetLink = (params.crsid === 'self') ? '/main/self' : `/main/form/${params.crsid}`;
  return (
    <div>
      <main>
        <p><Checkmark style={{ display: 'inline-block', marginLeft: 0 }} /> Thank you for submitting { (params.crsid === 'self') ? 'your' : 'the' } leaving form.</p>
        <p>You may now close this page.</p>
        <p>You can also <Link to={targetLink}>edit</Link> and then re-submit { (params.crsid === 'self') ? 'your' : 'the' } form if you wish to.</p>
      </main>
    </div>
  );
}
