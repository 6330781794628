import TextareaField from './my_theme/TextareaField';
//import DatePicker from './my_theme/DatePicker';
import DatePickerField from './my_theme/DatePickerField';
import YesNoNAField, {OKNAField, YesNoNotSureNAField, YesNoField} from './my_theme/YesNoNAField';
import SingleLineField from './my_theme/SingleLineField';
import Card from 'react-bootstrap/Card';
import ForwardingEmailAddressWhen from './ForwardingEmailAddressWhen';
import FloorTechnicianSelect from './FloorTechnicianSelect';
import FumeHoodChecked from './FumeHoodChecked';
import Spinner from './my_theme/Spinner';
import {Checkmark} from './react-checkmark/checkmark.js';
import ErrorBoundary from './my_theme/ErrorBoundary';
import str_to_date from './str_to_date';
import Button from './my_theme/Button';
import Heading from './my_theme/Heading';

import {
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import {Formik, Form as FormikForm, Field as FormikField} from 'formik';
//import { ReactstrapInput } from "./reactstrap-formik";

//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  useQuery,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";

import React, { Suspense } from "react";

import { useAuth } from "./auth.tsx";

const heading = {
    "1": 'Section 1: {pronouns.possessive_initial_capital} leaving date',
    "2": 'Section 2: Forwarding contact details',
    "3": 'Section 3: Prompts and declarations',
}

export function useQuestions(targetCrsid, edition, allow_start = null) {
    let auth = useAuth();
    let target_crsid_or_self = (targetCrsid === auth.crsid ? 'self' : targetCrsid);
    let target_crsid_not_self = (targetCrsid === 'self' ? auth.crsid : targetCrsid);
    if (allow_start === null) {
        allow_start = (target_crsid_or_self === 'self');
    }
    let navigate = useNavigate();
    let location = useLocation();
    return useQuery({
        queryKey: ["questions", target_crsid_not_self, edition],
        queryFn: async () => {
            const { data } = await auth.fetch_with_token.get("main/form", {"params": {"target_crsid": target_crsid_not_self, 'edition': edition, 'allow_start': allow_start}});
            return data;
        },
        onError: (error) => {
            if (error?.status === 401 && error?.response?.data?.detail === "JWT error - possibly expired") {
                navigate.navigate("/login", {'state': {'from': location.pathname } });
            }
        },
        placeholderData: {
            'target_person': {
                'full_name': (target_crsid_or_self === 'self' ? auth.userFullName : target_crsid_or_self),
                'crsid': (target_crsid_or_self === 'self' ? auth.crsid : target_crsid_or_self),
            },
        },
    });
}

function usePostResponses(targetCrsid, edition) {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const target_crsid_not_self = (targetCrsid === 'self' ? auth.crsid : targetCrsid);

  return useMutation(
    ({save, responses, submit}) => auth.fetch_with_token.post(`/main/form`, {'responses': responses}, {'params': {'target_crsid': target_crsid_not_self, 'edition': edition, 'save': save, 'submit': submit}}),
    {
      onSuccess: () => {
        // invalidate the responses that are already cached
        queryClient.invalidateQueries(['questions', target_crsid_not_self]);
      },
    }
  );
}

function substitutePronouns(str, pronouns) {
    return Object.keys(pronouns).reduce((accumulator, currentValue) => accumulator.replace('{pronouns.' + currentValue + '}', pronouns[currentValue]), str);
}

export default function Questions({data, targetCrsid, edition, pronouns, saveButtonText = "Save this section"}) {
    const auth = useAuth();
    const [btnClicked, setBtnClicked] = React.useState('');
    const [submittedText, setSubmittedText] = React.useState(<></>);
    const [lastSaved, setLastSaved] = React.useState(null); // TODO: default to the last saved datetime from the database

    const targetCrsidNotSelf = (targetCrsid === 'self' ? auth.crsid : targetCrsid);
    //const [mutationStatus, setMutationStatus] = React.useState(null);
    const responseMutator = usePostResponses(targetCrsid, edition ?? data.form_edition.edition);

    const components = {
        'Multiline': TextareaField,
        'YesNoNA': YesNoNAField, // ReactstrapInput
        'YesNo': YesNoField,
        'OKNA': OKNAField,
        'YesNoNotSureNA': YesNoNotSureNAField,
        'DatePicker': DatePickerField,
        'LeavingDateFixed': props => <DatePickerField includeDates={[str_to_date(data.leaving_date)]} {...props} />,
        'LeavingDateNoLater': props => <DatePickerField maxDate={[str_to_date(data.leaving_date)]} {...props} />,
        'SingleLine': SingleLineField,
        'ForwardingEmailAddressWhen': ForwardingEmailAddressWhen,
        'FloorTechnicianSelect': props => <FloorTechnicianSelect {...props} />,
        'FumeHoodChecked': props => <FumeHoodChecked targetCrsidNotSelf={targetCrsidNotSelf} {...props} />,
    };
    let initialValues = {};
    // this iteration is to figure out the intialValues only
    data.questions.map(function(v, idx) {
        if (v.Question.allow_notes) {
            let notes = "";
            if (v.LeaversFormDetail !== null) {
                notes = v.LeaversFormDetail.answer_notes;
                if (notes === null) {
                    notes = "";
                }
            }
            initialValues[v.Question.question_key + '_notes'] = notes;
        }
        initialValues[v.Question.question_key] = v?.LeaversFormDetail?.answer_detail ?? '';
        //initialValues[v.Question.question_key + '_detail'] = v?.LeaversFormDetail?.answer_detail ?? '';
        if ((v.Question.question_key === 'leaving_date') && initialValues[v.Question.question_key] === '') {
            initialValues[v.Question.question_key] = data.leaving_date; // this one is special!
        }
        if (v.Question.question_key === 'fwd_postal') {
            if (initialValues[v.Question.question_key] === '') {
                initialValues[v.Question.question_key] = data.forwarding_address; // this one is also special!
            }
        }
        if (v.Question.question_key === 'fwd_email') {
            if (initialValues[v.Question.question_key] === '') {
                if (!data.target_person.email.endsWith('@ch.cam.ac.uk') && !data.target_person.email.endsWith('@cam.ac.uk')) {
                    initialValues[v.Question.question_key] = data.target_person.email; // this one is also special!
                }
            }
        }
        return null;
    });

     const handleBlur = (question_key, e, original_onBlur, form) => {
        //console.log("blur for", question_key);
        if (original_onBlur) {
            original_onBlur(e);
        }
        // give the validation chance to execute first
        setTimeout(() => {
            // submit?
            if (form.isValid) {
                //console.log("blur for", question_key);
                form.submitForm().then(() => console.log("last saved:", new Date().toISOString()));
            }
        }, 0);
      };

      let previous_stage = null;
      let r = [];
      let question_number = 1;
      data.questions.map(function(v, idx) {
          const Widget = components[v.QuestionText.response_type];
          if (Widget === undefined) {
              throw new Error(`Undefined component: ${v.QuestionText.response_type}`);
          }
          const WidgetWithProps = ({field, ...props}) => <Widget field={{...field, onBlur: (e) => handleBlur(v.Question.question_key, e, field.onBlur, props.form)}} {...props} />;
          if (v.Question.form_stage !== previous_stage) {
              previous_stage = v.Question.form_stage;
              //console.log("previous_stage=", previous_stage);
              r.push(<li style={{listStyle: "square"}} key={"section_heading_" + previous_stage}>
                  <Heading>{substitutePronouns(heading[previous_stage], pronouns)}</Heading>
              </li>);
          }
          let notes_row_if_any = v.QuestionText.allow_notes ? (
                  <Row>
                  <Col><FormikField name={v.Question.question_key + '_notes'} component={TextareaField} style={{width: '100%'}} placeholder="Please enter any notes here as appropriate." /></Col>
                  </Row>
          ) : (<></>);
          r.push (
              <li key={v.Question.question_key} value={question_number}>
              <Card key={v.Question.question_key}>
              {/*<hr />*/}
                <Card.Body>
                <Card.Text dangerouslySetInnerHTML={{'__html': substitutePronouns(v.QuestionText.question_text, pronouns).replace('{database_leaving_date}', data.leaving_date)}} />
                <span style={{'display': 'none'}}>{v.QuestionText.response_type}</span>
                <Suspense fallback={<div>Loading {v.QuestionText.response_type}...</div>}>
                  <Row>
                  <Col><ErrorBoundary><FormikField name={v.Question.question_key} component={WidgetWithProps} placeholder={v.QuestionText.response_placeholder} /></ErrorBoundary></Col>
                  </Row>
                  {notes_row_if_any}
                </Suspense>
                {v.QuestionText.after_field_text ? <Card.Text dangerouslySetInnerHTML={{'__html': substitutePronouns(v.QuestionText.after_field_text, pronouns).replace('{database_leaving_date}', data.leaving_date)}} /> : <></>}
                {v.QuestionText.sensitive ? <p style={{ color: 'gray', fontSize: 11 }}>This question is marked as sensitive and only the HR team will be able see your answer to it.</p> : <></>}
              </Card.Body>
              </Card>
              </li>
          );
          question_number += 1;
          return null;
      });

    //console.log(r);
    //console.log("initialValues:", initialValues);

    return (<Formik
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={async (values, actions) => {
        // now send any values back to the server where they have actually changed!
        // note that things don't seem to appear here if they haven't been touched
        let changed_values = {};
        for (const [key, value] of Object.entries(values)) {
          if (value !== initialValues[key]) {
            changed_values[key] = {'from': initialValues[key], 'to': value};
          } else {
            changed_values[key] = {'unchanged': value};
          }
        }
        changed_values['all'] = values;
        console.log(JSON.stringify(changed_values, null, 2));
        //const save = (btnClicked !== '');
        const save = true;
        try {
            const result = await responseMutator.mutateAsync({save: save, responses: values, submit: btnClicked === 'submit'});
            console.log("mutator result:", result);
            console.log("btnClicked:", btnClicked);
            setLastSaved(new Date().toISOString());
            // if the mutation succeeded
            //setMutationStatus({'status': 'success'});
            // clear the dirty state of the form (which also resets the submit count etc)
            actions.resetForm({
                values: values,
            });
            if (btnClicked === 'submit') {
                console.log("Should redirect to submitted page...");
                setSubmittedText(<>Submission complete, thank you.<Navigate to={"/main/form_submitted/" + targetCrsid + "/" + data.form_edition.edition.toString() } replace={false} /></>);
            } else {
                console.log("btnClicked:", btnClicked);
            }
        } catch (error) {
            //setMutationStatus({'status': 'error', 'error': error});
            console.log(data);
            console.error("Error when saving/submitting form:", error);
            setSubmittedText(<div style={{backgroundColor: 'red', fontSize: 'large'}}>Failed to {btnClicked === 'submit' ? 'submit' : 'save'} form. {JSON.stringify(error?.response?.data?.detail)}  Please try again or contact <a href="mailto:support@ch.cam.ac.uk">support@ch.cam.ac.uk</a>.</div>);
        } finally {
            setBtnClicked('');
            actions.setSubmitting(false);
        }
      }}
      >
        {({ isSubmitting, dirty, setFieldValue, submitForm, values, touched }) => {
          let myStyle = {};
          let saveButtonSuffix = '';
          if (dirty) {
            myStyle.fontWeight = 'bold';
            saveButtonSuffix = '*';
          }
          if (isSubmitting) {
            // override any existing suffix
            saveButtonSuffix = <Spinner></Spinner>;
          }
          if (responseMutator.error) {
            myStyle.backgroundColor = 'red';
          }
          else if ((responseMutator.status === 'success') && !isSubmitting) {
            saveButtonSuffix = <Checkmark />
          }
          //console.log("responseMutator", responseMutator);

          let msgs = [];
          if (lastSaved !== null) {
            msgs.push(`Last saved: ${lastSaved}`);
          } else if (!dirty) {
            msgs.push("No changes to save yet.");
          } else {
            msgs.push("Changes need to be saved.");
          }
          if (!!isSubmitting) {
            msgs.push('Saving...');
          }

          return (
            <FormikForm>
              <ol>{r}</ol>
              {/*<Button type="submit" variant="primary" style={myStyle} disabled={!dirty} onClick={(event)=>{
                   setBtnClicked('save');
              }}>{ saveButtonText }{ saveButtonSuffix }</Button>*/}
              {/*dirty ? ' (* You have made changes to your answers since you last saved them.)' : ' (There are currently no unsaved changes.)'*/}
              Any changes you make to this form should be saved automatically.{' '}{dirty ? 'Your changes have not yet been saved.' : 'There are currently no unsaved changes.'}
              {/* isSubmitting ? ' is submitting!' : ' not submitting ' */}
              <p style={{ color: 'gray', fontSize: 11 }}>
                { msgs.join(", ") }
              </p>
              <div>
                When you have completed your form, then you may submit it.
                Your form may be automatically processed if you neglect to submit it before your leaving date.
              </div>
              {/*<p><Button type="submit" variant="primary" onClick={(event)=>{
                   setBtnClicked('submit');
              }}>{dirty ? "Save and submit now" : "Submit now (already saved)" }</Button></p> */}
              <p><Button type="submit" variant="primary" onClick={(event)=>{
                  setSubmittedText(<>Submitting...</>);
                  setBtnClicked('submit');
              }}>{/*dirty ? "Save and submit now" : "Submit now (already saved)" */}Submit</Button></p>
              { submittedText }
              <div>
                Upon submission, you will receive a copy of your completed form by email. Your line manager{"/"}host (and their administrator, if any) will receive an abridged version without your forwarding postal address. Other members of the Department's admin team may also be notified as appropriate.
                During the testing phase of this online system, IT support may also receive an abridged copy.
                You may be contacted about any discrepancies.
                You can still return to this form to make changes and re-submit again later if needed.
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    );
}
