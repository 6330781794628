import React from "react";
//import { useField } from "formik";
import DatePicker from "./DatePicker";
import str_to_date from '../str_to_date';

export default function DatePickerField({ field, form, children, ...props }) {
  //console.log("DatePickerField", props);
  //const [field, , { setValue }] = useField(props);
  //console.log("DatePicker field", field);
  //console.log("DatePicker props", props);
  //       {...field} // contains name, onBlur, onChange and value
  return (
    <DatePicker
      name={field.name}
      {...props}
      selected={(field.value && str_to_date(field.value)) || null}
      onBlur={field.onBlur}
      onChange={(val) => {
        if (val === null) {
          form.setFieldValue(field.name, '');
        } else {
          form.setFieldValue(field.name, val.toISOString().substring(0, 10));
        }
      }}
    />
  );
}
